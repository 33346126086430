import React from 'react';
import {
    Filter,
    SearchInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    TextInput
} from 'react-admin';
import {ConnectionStatus, InviteTypes, PremiumStatus, UserStatus} from "../constant/userConst";
import QuickFilter from "../../../../App/Component/Redesign/QuickFilter";
import {DeviceTypes} from "../../../Device/Device/constant/deviceConst";
import SearchByUserId from "./SearchByUserId";
import countries from "../../../Countries/Countries";
import communities from "../../../Communities/Communities";
import languages from "../../../Languages";
import identityGenders from "../../../../Pages/IdentityGenders";
import relationships from "../../../../Pages/Relationships";
import DateTimeInputMod from "../../../../App/Component/Redesign/Input/DateTimeInputMod";

const UsersFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Name" source="search" alwaysOn />
        <SearchByUserId source="userId" alwaysOn />
        <ReferenceInput label="Gender" source="gender" reference={identityGenders.name}>
            <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput label="Language" source="language" reference={languages.name} filterToQuery={searchText => ({ title: searchText })}>
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput label="Relationship status" source="relationship" reference={relationships.name}>
            <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput label="Community" source="community" reference={communities.name} filterToQuery={searchText => ({ title: searchText })}>
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <SelectInput label="Connection status" source="isLookingForConnection" choices={ConnectionStatus} />
        <SelectInput label="Premium status" source="premiumStatus" choices={PremiumStatus} />
        <ReferenceInput label="Country" source="countryId" reference={countries.name} filterToQuery={searchText => ({ name: searchText })}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput label="Phone" source="phone" />
        <TextInput label="Email" source="email" />
        <SelectInput label="User status" source="status" choices={UserStatus} />
        <SelectInput label="Source of registration type" source="invitationType" choices={InviteTypes} />
        <TextInput label="Source of registration token" source="invitationToken" />
        <QuickFilter source="isInfluencer" label="Is influencer" defaultValue={true} />
        <QuickFilter source="isCommunityLeader" label="Is community leader" defaultValue={true} />
        <SelectInput label="Device OS" source="deviceType" choices={DeviceTypes} />
        <DateTimeInputMod label="Date From" source="dateFrom" />
        <DateTimeInputMod label="Date To" source="dateTo" />
    </Filter>
);

export default UsersFilter;