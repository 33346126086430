import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    SelectInput,
    PasswordInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {AdminRolesList} from "../../../App/Component/Constant/Roles";
import {
    validateName,
    validateLastName,
    validateEmail,
    validatePassword,
    validateRepeatPassword,
    validateRole,
    validatePhoneCode,
    validatePhone
} from "./validation/validation";
import countries from "../../Countries/Countries";

export const optionRendererPhone = record => {
    return record.id ? `${record.name} (${record.dialCode})` : '';
}

export const AdminCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput fullWidth label="Name" source="name" validate={validateName} />
            <TextInput fullWidth label="Last Name" source="lastName" validate={validateLastName} />
            <TextInput fullWidth label="Email" source="email" type="email" validate={validateEmail} />
            <ReferenceInput fullWidth
                            label="Phone code"
                            source="countryId"
                            reference={countries.name}
                            filterToQuery={searchText => ({ name: searchText })}
                            allowEmpty={true}
                            validate={validatePhoneCode}>
                <AutocompleteInput optionText={optionRendererPhone} />
            </ReferenceInput>
            <TextInput fullWidth label="Phone number" source="phone" validate={validatePhone} />
            <PasswordInput fullWidth source="password" validate={validatePassword} autoComplete="new-password" />
            <PasswordInput fullWidth source="repeatPassword" validate={validateRepeatPassword} autoComplete="new-password" />
            <SelectInput fullWidth label="Role" source="role" validate={validateRole} choices={AdminRolesList} />
        </SimpleForm>
    </Create>
);